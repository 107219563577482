import About from "./Sections/about/about"
import Contact from "./Sections/contact/contact"
import FloatingNav from "./Sections/floating-nav/floating-nav"
import Footer from "./Sections/footer/footer"
import Header from "./Sections/header/header"
import Navbar from "./Sections/navbar/navbar"
import Portfolio from "./Sections/portfolio/portfolio"
import Services from "./Sections/services/services"
import FAQs from "./Sections/faqs/FAQs"
import {useRef, useState, useEffect} from 'react'

const App = () => {
  const mainRef = useRef();
  const [showFloatingNav, setShowFloatingNav] = useState(true);
  const [siteYPosition, setSiteYPosition] = useState(0)

  const showFloatingNavHandler = () =>{
    setShowFloatingNav(true);
  }

  const hideFloatingNavHandler = () => {
    setShowFloatingNav(false);
  }

  //check if floating nav should be shown or hidden
  const floatingNavToggleHandler = () =>{
    if(siteYPosition < (mainRef?.current?.getBoundingClientRect().y-20) || siteYPosition > (mainRef?.current?.getBoundingClientRect().y + 20)){
      showFloatingNavHandler();
    }else{
      hideFloatingNavHandler();
    }

    setSiteYPosition(mainRef?.current?.getBoundingClientRect().y);
  }

  useEffect (() =>{
    const checkYPosition = setInterval(floatingNavToggleHandler, 2000);

    //cleanup function
    return () => clearInterval(checkYPosition);
  }, [siteYPosition])



  return (
    <main>
      <Navbar/>
      <Header/>
      <About/>
      <Services/>
      <Portfolio/>
      <FAQs/>
      <Contact/>
      <Footer/>
      {showFloatingNav && <FloatingNav/>}
    </main>
  )
}

export default App