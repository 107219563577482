import AboutImage from '../../Assets/Portret1.jpg'
import CV from '../../Assets/CV2023.pdf'
import {HiDownload} from 'react-icons/hi'
import Card from '../../Components/card'
import data from './data'
import './about.css'

function About() {
  return (
    <section id="about" data-aos="fade-in">
      <div className="container about__container">
        <div className="about__left">
          <div className="about__portrait">
            <img src={AboutImage} alt="About" />
          </div>
            <div className="about__cvlink">
              <a href={CV} download className='btn primary'>Download CV <HiDownload/></a>
            </div>
        </div>
        <div className="about__right">
          <h2>About me</h2>
          <p>
            Programming is my passion. From a young age I have been developing my programming and game development skills, 
            which sparked my passions for game engines and artificial intelligence. Through education, work experience and my own entrepreneurship, 
            I have learned to work in a team-oriented and detail-oriented manner. 
          </p>
          <div className="about__cards">
            {
              data.map(item => (
                <Card key={item.id} className='about__card'>
                  <span className='about__card-icon'>{item.icon}</span>
                  <h5>{item.title}</h5>
                  <small>{item.desc}</small>
                </Card>
              ))
            }
          </div>
        </div> 
      </div>  
    </section>
  )
}

export default About