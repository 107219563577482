import {GiStarsStack} from 'react-icons/gi'
import {IoMdSchool} from 'react-icons/io'
import {GiArchiveResearch} from 'react-icons/gi'

const data = [
    {id: 1, icon: <GiStarsStack/>, title: 'Specialisation', desc: 'Artificial Intelligence & Game Development'},
    {id: 2, icon: <IoMdSchool/>, title: 'Education', desc: 'HBO Bachelor & Master'},
    {id: 3, icon: <GiArchiveResearch/>, title: 'Publisher', desc: 'Published article on towardsdatascience'}
    ]

    export default data;