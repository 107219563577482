import Image1 from '../../Assets/project1.jpg'
import Image2 from '../../Assets/project2.jpg'
import Image3 from '../../Assets/project3.jpg'
import Image4 from '../../Assets/project4.jpg'
import Image5 from '../../Assets/project5.jpg'
import Image6 from '../../Assets/project6.jpg'
import Image7 from '../../Assets/project7.jpg'
import Image8 from '../../Assets/project8.jpg'
import Image9 from '../../Assets/project9.jpg'
import Image10 from '../../Assets/project10.jpg'
import Image11 from '../../Assets/project11.jpg'
import Image12 from '../../Assets/project12.jpg'
import Image13 from '../../Assets/project13.jpg'
import Image14 from '../../Assets/project14.jpg'
import Image15 from '../../Assets/project15.jpg'

const data = [
    {
        id: 1,
        category: 'Game Development',
        image: Image1,
        title: "Adrenaline cruiser",
        desc: "In this Fasten Your Seatbelts project, my team and I developed a game using the Processing framework. We built numerous systems from scratch, resulting in a polished final product that exceeded expectations.",
        link1: 'https://www.youtube.com/watch?v=XV_XpksjObw',
        link2: 'https://github.com/erwinvc/Fys',
        name1: "Trailer",
        name2: "Github"
    },
    {
        id: 2,
        category: 'Game Development',
        image: Image2,
        title: "Ad Lucem",
        desc: "I developed this Unity game during my Game Mechanics course, focusing on game design and experimenting with normal mapping. The game is set in darkness, challenging players to analyze light patterns and strategize to progress.",
        link1: 'https://www.youtube.com/watch?v=FFwVyio9kQY',
        link2: '#',
        name1: "Trailer",
        name2: "No link"
    },
    {
        id: 3,
        category: 'Game Development',
        image: Image3,
        title: "Explora",
        desc: "As part of a team of five, I contributed to game design, world building, the crafting and inventory system, and post-processing effects in this six-month project.",
        link1: 'https://www.youtube.com/watch?v=SpQfJCdu8ro',
        link2: "#",
        name1: "Trailer",
        name2: "No Link"
    },
    {
        id: 4,
        category: 'Game Development',
        image: Image4,
        title: "Corpus Egg Game",
        desc: "During my internship at Virtual Play, I collaborated with an artist colleague to develop a game about eggs and chickens, handling various aspects of game development, such as shader development and stakeholder collaboration.",
        link1: '#',
        link2: '#',
        name1: "No Link",
        name2: "No Link"
    },
    {
        id: 5,
        category: 'Game Development',
        image: Image5,
        title: "Circulair Waste Project",
        desc: "In collaboration with AMFI students and game developers, I created a VR game to educate people about waste reduction, recycling, and the importance of proper waste separation.",
        link1: 'https://www.youtube.com/watch?v=4reZ2ckN3JE',
        link2: 'https://github.com/erwinvc/CirculairWasteProject',
        name1: "Trailer",
        name2: "Github"
    },
    {
        id: 6,
        category: 'Game Development',
        image: Image6,
        title: "Memoria",
        desc: "Memoria is a 2.5D platformer created by a two-person team, focusing on dementia awareness and elderly experiences. As Elena, players help her grandmother recover memories by exploring the neighborhood.",
        link1: 'https://wiavre3.wixsite.com/my-site-1',
        link2: 'https://github.com/erwinvc/Memoria',
        name1: "Website",
        name2: "Github"
    },
    {
        id: 7,
        category: 'Game Development',
        image: Image7,
        title: "Escape from Valhalla",
        desc: "Developed during the COVID-19 pandemic, this game was created by a team of 30 international students. As an audio designer, I contributed foley sounds and developed custom tooling in Unity for efficient collaboration.",
        link1: 'https://www.youtube.com/watch?v=KoKaDGbISuQ',
        link2: '#',
        name1: "Trailer",
        name2: "No Link"
    },
    {
        id: 8,
        category: 'Game Engine',
        image: Image8,
        title: "Student Engine",
        desc: "I led a team to create a C++ game engine designed for students, simplifying the development of small platformer games and incorporating comprehensive systems inspired by Unity.",
        link1: 'https://www.youtube.com/watch?v=2xEdV9iQYJA',
        link2: 'https://github.com/erwinvc/StudentEngine',
        name1: "Demo",
        name2: "Github"
    },
    {
        id: 9,
        category: 'Game Development',
        image: Image9,
        title: "Endless Metropolis",
        desc: "I recreated Bejeweled 2 with a synthwave twist, programming the core match-3 mechanics and developing a dynamic background generator to produce captivating visuals.",
        link1: 'https://github.com/erwinvc/Match3',
        link2: 'https://www.youtube.com/watch?v=sYiMdFGjY1s',
        name1: "Github",
        name2: 'Demo'
    },
    {
        id: 10,
        category: 'Game Engine',
        image: Image10,
        title: "Emerald Engine",
        desc: "The Emerald Game Engine is my most significant passion project, which I've been developing since the age of 16, starting with Java and OpenGL, and later transitioning to C++. I've created various projects within my engine, such as a Dungeon generator, Deep learning algorithm & visualization, and a Multiplayer Minecraft clone, to gain a deeper understanding of game engine inner workings. I also created a website (https://gameengine.jouwweb.nl/) to help others develop different aspects of their own game engines.",
        link1: 'https://github.com/erwinvc/Emerald',
        link2: 'https://www.youtube.com/watch?v=Qxd_SE8Ga7M',
        name1: "Github",
        name2: "Demo"
    },
    {
        id: 11,
        category: 'Reverse Engineering',
        image: Image11,
        title: "Unity",
        desc: "Developing the Unity Mod Menu for Grand Theft Auto 5 has been one of my most educational and enduring projects, despite its ethical concerns. This project allowed me to start and run my own business, selling the mod menu to over 5,000 customers and engaging with a community of thousands. My work involved software engineering, reverse engineering, stakeholder management, and marketing.",
        link1: 'https://www.youtube.com/watch?v=auUzoF5l-Pk',
        link2: '#',
        name1: "Demo",
        name2: "No Link"
    },
    {
        id: 12,
        category: 'Reverse Engineering',
        image: Image12,
        title: "Runelite plugin development",
        desc: "Combining my passion for Runescape with programming skills, I developed three Java-based plugins for the Runelite client, benefiting the Oldschool Runescape community. These plugins are used by over 30,000 users, showcasing my ability to contribute to open-source projects.",
        link1: 'https://github.com/erwinvc/runelite-plugins',
        link2: '#',
        name1: "Github",
        name2: "No Link"
    },
    {
        id: 13,
        category: 'AI',
        image: Image13,
        title: "Project Vision",
        desc: "I worked on a computer vision project for age verification at supermarket self-checkouts, using AI to estimate age and reduce workload for employees.",
        link1: '#',
        link2: '#',
        name1: "No Link",
        name2: "No Link"
    }
    ,
    {
        id: 14,
        category: 'AI',
        image: Image14,
        title: "QuestionAIre",
        desc: "QuestionAIre is a tool that gamifies Dutch reading literature by generating quizzes with AI-generated questions. The project involved AI models, dataset creation, and frontend development using Windows Presentation Foundation. QuestionAIre was featured on ICT-open 2023.",
        link1: 'https://www.youtube.com/watch?v=MLJPi2JLDSQ',
        link2: '#',
        name1: "Demo",
        name2: "No Link"
    }
    ,
    {
        id: 15,
        category: 'AI',
        image: Image15,
        title: "Towardsdatascience article",
        desc: "I conducted research on creating an AI model for generating Dutch questions, using translation techniques on existing datasets. My article was published on towardsdatascience.com and has been read by many.",
        link1: 'https://towardsdatascience.com/creating-a-dutch-question-answering-machine-learning-model-3b666a115be3',
        link2: '#',
        name1: "Article",
        name2: "No Link"
    }
]


export default data