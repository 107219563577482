import Projects from './projects'
import Projectcategories from './projectcategories'
import data from './data'
import {useState} from 'react'
import './portfolio.css'

const Portfolio = () => {
  const [portfolioprojects, setProjects] = useState(data);

  const categories = data.map(item => item.category);
  const uniqueCategories = ["all", ...new Set(categories)];

  const filterProjectsHandler = (category) => {
    if(category === "all"){
      setProjects(data);
      return;
    }
    const filterProjects = data.filter(project => project.category === category);
    setProjects(filterProjects);
  }

  return (
    <section id="portfolio">
      <h2>Recent Projects</h2>
      <p>
      Check out these recent projects I have worked on.
      </p>
      <div className="container portfolio__container">
        <Projectcategories categories={uniqueCategories} onFilterProjects={filterProjectsHandler}/>
        <Projects projects={portfolioprojects}/>
      </div>
    </section>
  )
}

export default Portfolio