import {links} from './data'
import './footer.css'
import {AiFillHeart} from 'react-icons/ai'

const Footer = () => {
  return (
    <footer>
      <div className="container footer__container">
        <ul className='nav__menu'>
          {
            links.map(fLink => <li key={fLink.id}><a href={fLink.link}>{fLink.title}</a></li>)
          }
        </ul>
      </div>
      <div className="footer__copyright">
        <small>Created with <AiFillHeart/> by WIANDI VREESWIJK &copy; All Rights Reserved 2023</small>
      </div>
    </footer>
  )
}
export default Footer