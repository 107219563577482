import Card from '../../Components/card'

const project = ({project}) => {
  return (
    <Card className="portfolio__project">
        <div className="portfolio__project-image">
            <img src={project.image} alt="Portfolio Project" />
        </div>
        <h4>{project.title}</h4>
        <p>{project.desc}</p>
        <div className="portfolio__project-cta">
            <a href={project.link1} className="btn sm" target="_blank" rel="noopner noreferrer">{project.name1}</a>
            <a href={project.link2} className="btn sm primary" target="_blank" rel="noopner noreferrer">{project.name2}</a>
        </div>
    </Card>
  )
}

export default project