import {AiFillCode} from 'react-icons/ai'
import {FaGamepad} from 'react-icons/fa'
import {GiBrain} from 'react-icons/gi'
import {BsFillCalendarWeekFill} from 'react-icons/bs'
import {BiGitBranch} from 'react-icons/bi'
import {BsWrench} from 'react-icons/bs'


const data = [
    {id:1, icon: <AiFillCode/>, title: 'Programming', 
    desc: "Programming: As an enthusiastic programmer, I am skilled in languages such as C#, C++, Java, and Python, and have successfully developed a custom game engine using C++ to showcase my proficiency in the field."},
    {id:2, icon: <FaGamepad/>, title: 'Game Development', 
    desc: "Harnessing the power of Unity and my own game engine, I have gained extensive experience in creating both serious and entertainment-based games. My specialty lies in Unity tooling development, but I am well-versed in all aspects of game development and possess a deep understanding of game engine mechanics."},
    {id:3, icon: <GiBrain/>, title: 'Applied Artificial Intelligence', 
    desc: "My master's program provided me with valuable experience in AI, particularly in computer vision and natural language processing. I have developed practical solutions for age estimation and NLP applications in education, while honing my ability to work with stakeholders and navigate the ethical complexities of AI."},
    {id:4, icon: <BsFillCalendarWeekFill/>, title: 'Agile Workflow', 
    desc: "With a strong foundation in Scrum and Kanban methodologies, I have efficiently managed projects using Agile principles to ensure timely delivery and optimal results."},
    {id:5, icon: <BiGitBranch/>, title: 'Git', 
    desc: "Possessing extensive experience with Git, I am proficient in version control and have utilized this vital tool in numerous projects to maintain code integrity and collaborate effectively with team members."},
    {id:6, icon: <BsWrench/>, title: 'Reverse Engineering', 
    desc: "Though not an expert in reverse engineering, I find this skill noteworthy due to its rarity. My experience includes working on game cheats, requiring reverse engineering knowledge to effectively manipulate games. Proficient in IDA Pro disassembler, Cheat Engine, x86/x64 assembly, and computer memory interpretation, I've successfully integrated visual cheat menus into various games. For me this skill is invaluable in programming, as it grants fundamental code understanding and insights into more efficient approaches."}
]
export default data;