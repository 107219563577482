import { useEffect } from 'react'
import HeaderImage from '../../Assets/Portret3.jpg'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './header.css'

function Header() {
  useEffect(() =>{
    AOS.init({duration:2000})
  }, [])

  return (
    <header id="header">
      <div className="container header__container">
        <div className="header__profile" data-aos="fade-in">
          <img src={HeaderImage} alt="Header Portrait"/>
        </div>
        <h2 data-aos="fade-in">Erwin van Crasbeek</h2>
        <div className="header__cta">
          <a href="#contact" className='btn primary'>Contact me</a>
          <a href="#portfolio" className='btn light'>Portfolio</a>
        </div>
      </div>
    </header>
  )
}

export default Header