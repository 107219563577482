const data = [
    {
      id: 1,
      question: "What are your weaknesses?",
      answer: "My primary weakness is a fear of independently undertaking new steps. To overcome this, I have taught myself to seize every opportunity that comes my way with both hands."
    },
    {
      id: 2,
      question: "Can you describe a challenging project you've worked on and how you overcame the obstacles?",
      answer: "One of the most challenging projects I've encountered was the development of Memoria, an entertainment game with serious undertones, created by a two-person team over the course of a year during the COVID-19 pandemic. The remote work environment, uncertainty about the future, and the need to design, develop, and release a complete game pilot within a tight deadline posed significant challenges. To overcome these obstacles, we implemented efficient communication tools and strategies (Kanban, Git, Trello, Discord), prioritized tasks effectively, and maintained a disciplined work schedule. Additionally, we embraced a high workload and strict deadlines to ensure the successful completion of the project."
    },
    {
      id: 3,
      question: "What are your current projects or endeavors?",
      answer: "I am presently in the final phase of my Applied Artificial Intelligence master's program, collaborating with the Hogeschool van Amsterdam Smart Education Lab to leverage AI technology in enhancing their educational game. Simultaneously, I am actively exploring potential opportunities to advance my career upon graduation."
    },
    {
      id: 4,
      question: "How would you describe your soft skills, and how do you continue to develop them?",
      answer: "My soft skills include an interest in social issues, strong communication, problem-solving,  flexibility, and creativity. I continuously develop these skills by engaging in teamwork, seeking feedback from peers and mentors, staying updated with industry trends, and embracing new challenges."
    },
    {
      id: 5,
      question: "What are your hobbies and interests outside of work hours?",
      answer: "I thoroughly enjoy walking in nature. It’s a great way to unwind after a busy day and since I currently live in Noord-holland, I’m surrounded by beautiful nature with almost endless exploration possibilities. Naturally, as a game developer, I enjoy spending my leisure time on gaming. Some of my favorite games include Oldschool Runescape, TheHunter: Call of the Wild, ARK survival evolved and Sea of Thieves."
    }
  ]

  export default data